import React, { Component } from 'react';
import CareersSubNav from './CareersSubNav';
import AboutFeaturedLogos from './AboutFeaturedLogos';
import $ from "jquery";
import LogoList from './LogoList';

class Internships extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logos: []
        };
    }

    componentDidMount() {
        let self = this;
        $.get('https://corpcms.cminternalcontrol.com/api/Networks', function (data) {
            data = data.sort(function (a, b) { return a.Sort - b.Sort });
            self.setState({
                logos: data
            });
        });
    }
    render() {
        return (
            <div>
                <CareersSubNav currentActive={"INTERNSHIPS"} />
                <div className="below-fixed-subnav">
                    <div className="container">
                        <div className="about-us-header col-xs-12">
                            <span className=""></span>
                            <div className="">
                                <h2>INTERNSHIPS AT HALLMARK MEDIA</h2>
                            </div>
                            <span className=""></span>
                        </div>
                    </div>
                    <AboutFeaturedLogos uneven data={this.state.logos} />
                    <div className="internship-container">
                        <div className='channel-logo-container'>
                            {/* <LogoList /> */}
                        </div>
                    </div>
                    <div className="internship-content">
                        <div className='intern-imgContainer22'>
                            {/* <div className="about-us-header col-xs-12 intern-img">

                                <img className='intern-img' src='/images/Intern2022WebV2-2.png' />

                                <div className='intern-contactInfo'>
                                    <p className='email contentText'><i>For more information about our internship program please email <a className="home-page-a-email" href="mailto:hallmarkmediarecruiting@hallmarkmedia.com">hallmarkmediarecruiting</a></i></p>
                                    <p className='contentText'><i>We are now accepting applications for Summer 2022 on our <a className='home-page-a-careers' href='https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=32864&clientkey=477B620764F4A7BADB21CD8BE2241D3A'>careers page</a></i></p>

                                </div>
                            </div> */}
                            <div className="col-xs-12 contentContainer">
                                <div><p className='email contentText-center'><i>Please stay tuned for more information about our internship program. For any questions in the meantime, please email <a className="home-page-a-email" href="mailto:hallmarkmediarecruiting@hallmarkmedia.com">hallmarkmediarecruiting@hallmarkmedia.com</a></i></p></div>
                            </div>
                        </div>

                        {/* <div className="col-xs-12">
                            <header className='internship-header'>
                                <h4 className='internship-h4'>Check Back in January 2022 for our Summer Internship Opportunities</h4>
                                <h4 className='internship-h4'>Summer 2021 (June – August)</h4>
                            </header>
                            <article className='opportunities-content'>
                                <p className="about-us-p first-sentence">Hallmark Media’ Internship Program provides an environment of high impact learning integrated with real-world experience and transferable skill development opportunities.</p>
                                <p className="about-us-p first-text">Opportunities to intern within some of the following teams may be available:</p>
                            </article>
                            <article className="row opportunities-row">
                                <div className="center-this">
                                    <ul className="internship-ul">
                                        <li>Brand Production & Operations</li>
                                        <li>Brand Creative</li>
                                        <li>Corporate Communications</li>
                                        <li>Production Finance</li>
                                        <li>Programming</li>
                                    </ul>
                                </div>
                                <div className="col-sm-6 right-col">
                                    <ul className="internship-ul">
                                        <li>Ad Sales</li>
                                        <li>Brand – Creative, Design, Marketing, Production & Operations</li>
                                        <li>Consumer Insights & Research</li>
                                        <li>Corporate Communications, Publicity & Social Media</li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <ul className="internship-ul">
                                        <li>Digital & New Media</li>
                                        <li>Distribution</li>
                                        <li>Finance</li>
                                        <li>Human Resources & Administration</li>
                                        <li>Programming & Development</li>
                                    </ul>
                                </div>
                            </article>
                            <article className='end-article'>
                                <p className="about-us-p first-text">Internship placements are aligned based on career goals and education major.</p>
                                <p className="about-us-p first-text">For more information about our internship program please email<a className="home-page-a-email" href="mailto:internships@crownmedia.com">internships@crownmedia.com</a></p>
                                <p className="about-us-p center red"><span className='red'>Fall Internship applications closed. Visit our </span><a className='home-page-a-careers' href='https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=29457&clientkey=477B620764F4A7BADB21CD8BE2241D3A'>careers page</a> for other opportunities at Hallmark Media.</p>
                            </article>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Internships;